import React from 'react';
import './../css/WorkoutDefinitionTable.css';
import WorkoutDefinitionCard from './WorkoutDefinitionCard.js';
import { Modal } from '@material-ui/core';
import WorkoutDefinitionBuilder from './WorkoutDefinitionBuilder';
import CreateIcon from '@material-ui/icons/Create';
import TimelineIcon from '@material-ui/icons/Timeline';
import TocIcon from '@material-ui/icons/Toc';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';

import LineChart from 'react-linechart';
import '../../node_modules/react-linechart/dist/styles.css';

class WorkoutDefinitionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workoutDefinitionRows: [],
            open: false,
            isLoadingData: true,
            rowsData: [],
            chartData: [],
            showTable: true,
            showMetricTable: true,
            metricDefinitionName: ""
        }

        this.handleOpen = this.handleOpen.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.showWorkoutDefinitionMetrics = this.showWorkoutDefinitionMetrics.bind(this);
        this.toggleHistoryVisual = this.toggleHistoryVisual.bind(this);
        this.showCardTable = this.showCardTable.bind(this);
        this.getDefinitionByDefinitionId = this.getDefinitionByDefinitionId.bind(this);
        this.getExerciseInstancesForWorkoutInstanceId = this.getExerciseInstancesForWorkoutInstanceId.bind(this);
        this.buildChartData = this.buildChartData.bind(this);
    }

    componentDidMount() {
        this.props.workoutDefinitionService.getAllWorkoutDefinitions().then(function(response){
            let rows = [];
            let allWorkoutDefinitions = response.data.Items;
            for (let i in allWorkoutDefinitions) {
                rows.push(
                    <div
                        className="workout-definition-table-card-container"
                        key={i}
                    >
                        {/* Need to move this metrics calculation to a button or something other than clicking the container to make the export feature easier */}
                        {/* <button onClick={this.showWorkoutDefinitionMetrics.bind(this, allWorkoutDefinitions[i])}>Metrics</button> */}
                        <WorkoutDefinitionCard 
                            workoutDefinition={allWorkoutDefinitions[i]} 
                            workoutInstanceService={this.props.workoutInstanceService}
                            exerciseDefinitionService={this.props.exerciseDefinitionService}
                            exerciseInstanceService={this.props.exerciseInstanceService}
                        >
                        </WorkoutDefinitionCard> 
                    </div>
                );
            }
            this.setState({
                workoutDefinitionRows: rows,
                isLoadingData: false
            });
        }.bind(this));
    }

    handleOpen(event) {
        this.setState({
            open: true
        });
    }

    closeModal(){
        this.setState({
            open: false
        });
    }

    getDefinitionByDefinitionId(definitionId){
        if (this.definitionCache){
            return this.definitionCache[definitionId];
        }
        this.definitionCache = {};
        for (let i in this.definitions){
            this.definitionCache[this.definitions[i].id.S] = this.definitions[i];
        }
        return this.definitionCache[definitionId];
    }

    getExerciseInstancesForWorkoutInstanceId(workoutInstanceId, allExerciseInstances) {
        if (this.workoutInstanceIdToExerciseInstances){
            return this.workoutInstanceIdToExerciseInstances[workoutInstanceId];
        }
        this.workoutInstanceIdToExerciseInstances = {};
        // building a map of workout instance to the exercise instance objects
        for (let i in this.workoutInstances){
            let currWorkoutInstance = this.workoutInstances[i];
            let exerciseInstanceIds = currWorkoutInstance.exerciseInstanceIds.SS;
            let exerciseIntancesForWorkoutInstance = [];
            for (let k in allExerciseInstances){
                if (exerciseInstanceIds.includes(allExerciseInstances[k].id.S)){
                    exerciseIntancesForWorkoutInstance.push(allExerciseInstances[k]);
                    if (exerciseIntancesForWorkoutInstance.length === exerciseInstanceIds.length){
                        break;
                    }
                }
            }
            this.workoutInstanceIdToExerciseInstances[currWorkoutInstance.id.S] = exerciseIntancesForWorkoutInstance;
        }
        return this.workoutInstanceIdToExerciseInstances[workoutInstanceId];
    }

    /**
     * This entire method is super innefficient but does what I need to do in order to show metrics
     * Could be improved by storing calculated values rather than doing all this calculation in the browser
     */
    showWorkoutDefinitionMetrics(definition){
        this.setState({
            isLoadingData: true
        });
        console.log("Generating metrics for definition: " + definition);
        let dynamoIds = definition.exerciseDefinitionIds.L;
        let exerciseDefIds = [];
        for (let i in dynamoIds){
            exerciseDefIds.push(dynamoIds[i].S);
        }
        this.props.exerciseDefinitionService.getExerciseDefinitionByIds(exerciseDefIds).then(function(response){
            this.definitions = response.data.Items;
            this.props.workoutInstanceService.getWorkoutInstancesByDefinitionId(definition.id.S).then(function(response){
                this.workoutInstances = response.data.Items;
                this.workoutInstances.sort(function (a, b) {
                    if (a.date.N > b.date.N) {
                        return -1;
                    }
                    if (a.date.N < b.date.N) {
                        return 1;
                    }
                    return 0;
                });
                if (this.workoutInstances.length > 0){
                    // Super innefficient, need method to support fetching execise instance by id(s)
                    this.props.exerciseInstanceService.getAllExerciseInstances().then(function(response){
                        let allExerciseInstances = response.data.Items;
                        let exerciseInstanceWeightRows = [];
                        // building the table headers
                        let header = [];
                        header.push(<div key="exerciseDefTableHeader" className="table-item">Date</div>);
                        for (let n in exerciseDefIds){
                            let exerciseDefinition = this.getDefinitionByDefinitionId(exerciseDefIds[n]);
                            let key="exerciseDefTableHeader" + n;
                            let tableHeader = exerciseDefinition.definitionName.S + " " + exerciseDefinition.sets.N + " x " + exerciseDefinition.reps.N
                            header.push(<div key={key} className="table-item">{tableHeader}</div>);
                        }
                        exerciseInstanceWeightRows.push(<div key="instanceTable">{header}</div>);
                        // Building the rows for the table info
                        let chartData = {};
                        for (let i in this.workoutInstances){
                            let row = [];
                            let workoutInstance = this.workoutInstances[i];
                            let exInstances = this.getExerciseInstancesForWorkoutInstanceId(workoutInstance.id.S, allExerciseInstances);
                            let workoutDate = new Date(parseFloat(workoutInstance.date.N.toString()));
                            let formattedDate = workoutDate.getMonth() + 1 + "/" + workoutDate.getDate() + "/" + workoutDate.getFullYear();
                            row.push(<div className="table-item">{formattedDate}</div>);
                            for (let p in exerciseDefIds) {
                                let exerciseDefId = exerciseDefIds[p];
                                let weight = "";
                                for (let q in exInstances){
                                    if (exInstances[q].exerciseDefinitionId.S === exerciseDefId){
                                        weight = exInstances[q].weight.N.toString();
                                        break;
                                    }
                                }

                                if (!chartData[exerciseDefId]){
                                    // fetch the definition name by id
                                    let exerciseDefinition = this.getDefinitionByDefinitionId(exerciseDefId);
                                    let lineName = exerciseDefinition.definitionName.S + " " + exerciseDefinition.sets.N + " x " + exerciseDefinition.reps.N;
                                    // create first entry in data
                                    chartData[exerciseDefId] = {
                                        points: [],
                                        lineName: lineName
                                    }
                                }
                                let arr = chartData[exerciseDefId].points;
                                arr.push({
                                    x: workoutDate.getFullYear() + "-" + (workoutDate.getMonth() + 1) + "-" + workoutDate.getDate(),
                                    y: weight
                                });
                                chartData[exerciseDefId].points = arr;
                                
                                let key = "tableItem" + p;
                                row.push(<div key={key} className="table-item">{weight} lbs</div>);
                            }

                            let key = "row" + i;
                            exerciseInstanceWeightRows.push(<div key={key}>{row}</div>);
                        }
                        let finalRows = [];
                        finalRows.push(
                            <div className="workout-def-metrics-table-container">
                                {exerciseInstanceWeightRows}
                            </div>
                        );
                        let data = this.buildChartData(chartData);

                        this.setState({
                            rowsData: finalRows,
                            chartData: data,
                            showTable: false,
                            metricDefinitionName: definition.definitionName.S,
                            isLoadingData: false
                        });
                    }.bind(this));
                }
            }.bind(this));
        }.bind(this));
    }

    /**
     * Builds out the line chart data in the format required for react line chart given the raw data
     */
    buildChartData(rawData){
        let data = [];
        let colorArr = ["#152028", "#47555E", "#828B92", "black"];
        let counter = 0;
        for (let key in rawData){
            if (rawData[key].points.length > 0){
                data.push({
                    color: colorArr[counter++],
                    points: rawData[key].points,
                    name: rawData[key].lineName
                });
            }
        }
        return data;
    }

    toggleHistoryVisual(){
        let metricTableVal = this.state.showMetricTable;
        this.setState({
            showMetricTable: !metricTableVal
        });
    }

    showCardTable(){
        this.setState({
            showTable: true
        });
    }

    render() {
        return (
            <div className="workout-definition-table-container">
                <div hidden={!this.state.isLoadingData} className="loading-spinner-container exercise-definition-table-loading-spinner-container">
                    <CircularProgress></CircularProgress>
                </div>
                <div hidden={this.state.isLoadingData}>
                    <div>
                        <button className="primary-button" onClick={this.handleOpen}><CreateIcon/> Create New Workout</button>
                    </div>
                    {/* Card Based Table */}
                    <div hidden={!this.state.showTable}>
                        {this.state.workoutDefinitionRows}
                    </div>
                    {/* Metrics For Definition */}
                    <div hidden={this.state.showTable}>
                        <div className="card">
                            <div>
                                <div className="metric-container-header"><span onClick={this.showCardTable}><ArrowBackIcon/></span>{this.state.metricDefinitionName}</div>
                                <div hidden={!this.state.showMetricTable}>
                                    <button className="icon-button" onClick={this.toggleHistoryVisual}><TimelineIcon/></button>
                                </div>
                                <div hidden={this.state.showMetricTable}>
                                    <button className="icon-button" onClick={this.toggleHistoryVisual}><TocIcon/></button>
                                </div>
                            </div>
                            <div 
                                hidden={!this.state.showMetricTable}
                            >
                                {this.state.rowsData}
                            </div>
                            <div
                                hidden={this.state.showMetricTable}
                            >
                                <LineChart  
                                    data={this.state.chartData}
                                    xLabel="Date"
                                    yLabel="Weight (lbs)"
                                    yMin="0"
                                    yMax="300"
                                    isDate="true"
                                    height="500"
                                    onPointHover={(obj) => `${obj.y} lbs`}
                                    showLegends="true"
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.open}
                            onBackdropClick={this.closeModal}
                        >
                            <WorkoutDefinitionBuilder
                            exerciseDefinitionService={this.props.exerciseDefinitionService} 
                            muscleGroupService={this.props.muscleGroupService}
                            workoutDefinitionService={this.props.workoutDefinitionService}
                            ></WorkoutDefinitionBuilder>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}

export default WorkoutDefinitionTable;