// This project uses amplify to perform basic authentication but no authorization currently with the following configuration.
// It does not use amplify to add authentication but instead using traditional cloud formation templates.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_WceCXOjeD",
    "aws_user_pools_web_client_id": "6cd44jgd66c3ha824i4kfbcje0",
    "oauth": {},
    API: {
        endpoints: [
            {
                name: "ExerciseInstanceService",
                endpoint: "https://exercise-instance-api-prod.myworkoutjournals.com"
            },
            {
                name: "WorkoutInstanceService",
                endpoint: "https://workout-instance-api-prod.myworkoutjournals.com"
            },
            {
                name: "ExerciseDefinitionService",
                endpoint: "https://exercise-definition-api-prod.myworkoutjournals.com"
            },
            {
                name: "WorkoutDefinitionService",
                endpoint: "https://workout-definition-api-prod.myworkoutjournals.com"
            },
            {
                name: "ProductStatisticsService",
                endpoint: "https://product-statistics-api-prod.myworkoutjournals.com"
            },
            {
                name: "ExerciseInstancesLocal",
                endpoint: "http://127.0.0.1:3000"
            },
            {
                name: "WorkoutInstancesLocal",
                endpoint: "http://127.0.0.1:3002"
            },
            {
                name: "ExerciseDefinitionsLocal",
                endpoint: "http://127.0.0.1:3003"
            },
            {
                name: "WorkoutDefinitionsLocal",
                endpoint: "http://127.0.0.1:3004"
            },
            {
                name: "ProductStatisticsLocal",
                endpoint: "http://127.0.0.1:3005"
            }
        ]
    }
};

export default awsmobile;