import React from 'react';
import './../css/QuickLogBuilder.css';
import SaveIcon from '@material-ui/icons/Save';

class QuickLogBuilder extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            saveText: ""
        }

        this.saveWorkoutInstance = this.saveWorkoutInstance.bind(this);
    }

    // Sample Input
// Date:8/7/2021
// Duration:56
// 1:Workout 1
// 2:Barbell Bench:3:6:weight:20
// 1:Barbell Bench:2:15:weight:30
// 6:Pushups:3:5:weight:50
// 5:Barbell Bench:3:4:weight:80
// 4:Dumbbell Incline Fly:3:10:weight:20
// 3:Barbell Incline Bench:3:8:weight:15
    async saveWorkoutInstance(){
        let quicklogInput = document.getElementById("textareaInput").value;
        var lines = quicklogInput.split("\n");
        let dateLine = lines[0];
        let date = dateLine.split(":")[1];
        let dateMillis = new Date(date).getTime();
        let durationLine = lines[1];
        let duration = durationLine.split(":")[1];
        let workoutDefLine = lines[2];
        let workoutDefId = workoutDefLine.split(":")[0];

        let exerciseInstanceIds = [];
        for (let i in lines) {
            if (i > 2){
                let exerciseLine = lines[i];
                let exerciseSplit = exerciseLine.split(":");
                let weight = exerciseSplit[5];
                let exDefId = exerciseSplit[0];
                
                let response = await this.props.exerciseInstanceService.addExerciseInstance(dateMillis, exDefId, parseInt(weight));
                exerciseInstanceIds.push(response.data.id.S);
            }
        }
        let workoutInstance = {
            "workoutDefinitionId":workoutDefId,
            "date": dateMillis,
            "exerciseInstanceIds":exerciseInstanceIds,
            "duration":parseInt(duration)
        }
        this.props.workoutInstanceService.addWorkoutInstance(workoutInstance.date, workoutInstance.workoutDefinitionId, workoutInstance.exerciseInstanceIds, workoutInstance.duration).then(function(response){
            this.setState({
                saveText: "Workout Instance Saved!"
            })
        }.bind(this));
    }

    render() {
        return (
            <div className="quicklog-builder-container">
                <div className="textarea-container">
                    <textarea id="textareaInput" className="quicklog-textarea" placeholder="Insert quick log here"></textarea>
                </div>
                <div className="save-button-container">
                    <button 
                        className="secondary-button" 
                        onClick={this.saveWorkoutInstance}
                    >
                        <SaveIcon/> Save
                    </button>
                </div>
                <div>
                    {this.state.saveText}
                </div>
            </div>
        );
    }
}

export default QuickLogBuilder;